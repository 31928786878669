import { TagsInput } from '@mantine/core';
import { IFieldComponentProps } from '../types';

export const CustomWordsField = ({ form }: IFieldComponentProps) => (
  <TagsInput
    label="Нажмите Enter, чтобы добавить слово черный список"
    placeholder="Введите слово"
    clearable
    maxTags={10}
    splitChars={[',', ' ']}
    {...form.getInputProps('customWords')}
  />
);
