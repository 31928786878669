import React, {PropsWithChildren} from 'react';
import Header from 'src/components/Layout/Header';
import classes from './Layout.module.scss';
import {Container} from '@mantine/core';
import {Footer} from './Footer';
import {DonateOpportunityAlert} from './DonateOpportunityAlert';

const Layout = (props: PropsWithChildren & { title: string }) => {
  return (
    <div className={classes.globalWrapper}>
      <Header title={props.title} className={classes.header} />
      <main className={classes.main}>
        <Container className={classes.container} size={'responsive'}>
            <DonateOpportunityAlert />
          {props.children}
        </Container>
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
