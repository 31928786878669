import { Collapse, FileInput, Flex, Radio, Space } from '@mantine/core';
import { IFieldComponentProps } from '../types';
import { ACCEPT_FORMATS } from '../constants';

export const WayOfBLockingWordsField = ({ form }: IFieldComponentProps) => (
  <Radio.Group label="Способ блокировки нежелательных слов:" {...form.getInputProps('wayOfBlockingWords')}>
    <Flex direction={'column'} gap={8}>
      <Radio value="default" label="Стандартный" />
      <Radio value="mute" label="Просто заглушить(нежелательные слова заменятся на тишину)" />

      <div>
        <Radio value="customSound" label="Выбрать свой звук" />
        <Space h="xs" />
        <Collapse in={form.values.wayOfBlockingWords === 'customSound'}>
          <FileInput
            label="Выберите аудиофайл, которым хотели бы заменить нежелательные слова"
            placeholder="Выберите файл"
            accept={ACCEPT_FORMATS}
            {...form.getInputProps('replaceSound')}
          />
        </Collapse>
      </div>
    </Flex>
  </Radio.Group>
);
