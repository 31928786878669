import classes from '../uploadMediaForm.module.scss';
import { Group, Radio } from '@mantine/core';
import { IFieldComponentProps } from '../types';

export const WayOfUsingCustomWordsRadiosField = ({ form }: IFieldComponentProps) => (
  <Radio.Group
    label="Свой словарь:"
    className={classes.wayOfUsingCustomWordsRadios}
    {...form.getInputProps('wayOfUsingCustomWords')}
  >
    <Group>
      <Radio value="addToDefaultDict" label="Дополнить стандартный словарь" />
      <Radio value="useOnlyCustomDict" label="Использовать только пользовательский словарь" />
    </Group>
  </Radio.Group>
);