import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import result from 'src/store/Result';
import Layout from '../../components/Layout';
import {Badge, Box, Button, Card, Center, Group, LoadingOverlay, Space, Spoiler, Text, Title} from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import classes from './resultPage.module.scss';
import { themeColors } from '../../shared/theme/theme';
import { useDownload } from './hooks';
import { routes } from '../../routes';

const ResultPage = observer<any>(() => {
  const navigate = useNavigate();
  const {download, progress} = useDownload();

  useEffect(() => {
    document.title = 'VideoCensor | Результат удаления нецензурной лексики из видео/аудиофайла';
  }, []);

  const handleSuccessButton = useCallback(async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const link = result.result?.downloadLink;
    if (link) {
      await download(link);
    }
  }, [download]);

  const goToMain = useCallback(() => {
    navigate('/', { replace: true });
    result.reset();
  }, [navigate]);

  useEffect(() => {
    if (!result?.result?.downloadLink) {
      result.reset();
      navigate(routes.main.link);
    }
  }, [navigate]);

  if (!result?.result?.downloadLink) {
    return null;
  }

  return (
    <Layout title={'Результат'}>
      <Card className={classes.card} shadow="sm" radius="md" withBorder>
        <Card.Section pt="md" mb="md" style={{ textAlign: 'center' }}>
          <video className={classes.video} src={result.result.downloadLink} controls key="video" preload='auto' />
        </Card.Section>

        <Group justify="space-between" mb="lg">
          <Badge color={themeColors.success}>Файл успешно обработан</Badge>
          <Badge color={themeColors.secondary}>Слов удалено: {result.result?.count}</Badge>
        </Group>

        <Spoiler maxHeight={0} showLabel={'Показать распознанный текст (18+)'} hideLabel={'Скрыть'} styles={{control: {color: themeColors.secondary, fontWeight: 700}}}>
          <Title order={4}>Распознанный текст:</Title>
          <Space h="4px" />
          <Text size="sm">{result.result?.text}</Text>
        </Spoiler>

        <Space h="xl" />

        <Center>
          <Box pos={'relative'}>
            <LoadingOverlay
                visible={progress !== null}
                overlayProps={{ radius: 'sm', blur: 4, backgroundOpacity: 0.5, color: themeColors.dark }}
                loaderProps={{ children: `${progress}%`, c: themeColors.lightFontC }} />
            <Button
                component="a"
                href={result.result?.downloadLink}
                onClick={handleSuccessButton}
                rightSection={<IconDownload size={14} />}
                variant="gradient"
                gradient={{ from: themeColors.primary, to: themeColors.dark, deg: 80 }}
            >
              Скачать
            </Button>
          </Box>
          <Space w="md" />
          <Button className={classes.goToMainBtn} onClick={goToMain} variant="transparent">
            На главную
          </Button>
        </Center>
      </Card>
    </Layout>
  );
});

export default ResultPage;
