import {Alert, Text} from '@mantine/core';
import {IconHeart} from '@tabler/icons-react';
import {themeColors} from '../../../shared/theme/theme';
import {routes} from '../../../routes';
import React, {useEffect, useState} from 'react';

export const DonateOpportunityAlert = () => {
    const isShownSubAlert = window.localStorage.getItem('isShownSubAlert') === 'true';
    const [isShow, setIsShow] = useState(!isShownSubAlert && window.location.pathname !== routes.donate.link);


    useEffect(() => {
        window.addEventListener('beforeunload', function (e) {
            window.localStorage.setItem('isShownSubAlert', 'true');
        });
    }, []);

    const onClose = () => {
        window.localStorage.setItem('isShownSubAlert', 'true');
        setIsShow(false);
    }

    return isShow
            ?  <Alert onClose={onClose} title="Дорогие пользователи сервиса VideoCensor!" icon={<IconHeart />} withCloseButton color={themeColors.success} radius="lg">
                    <Text>С недавних пор в сервисе введена подписочная система. Чтобы получить подписку вам необходимо войти в профиль и приобрести подписку.</Text>
                    <Text>Благодарим вас за пользование нашим сервисом ❤️</Text>
                </Alert>
            : null;
}