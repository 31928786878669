import { Icon, IconHome, IconPhone, IconInfoCircle, IconHeartDollar, IconUser } from '@tabler/icons-react';

export type IRoute = { link: string; label: string; Icon: Icon };

export const routes: { [key: string]: IRoute } = {
  main: { link: '/', label: 'Главная', Icon: IconHome },
  result: { link: '/result', label: 'Результат', Icon: IconPhone },
  about: { link: '/about', label: 'О Нас', Icon: IconInfoCircle },
  donate: { link: '/donate', label: 'Поддержать проект', Icon: IconHeartDollar },
  profile: { link: '/profile', label: 'Профиль', Icon: IconUser },
};
