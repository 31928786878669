import Dropzone from '../../Dropzone';
import { Badge, Box, Space, Text } from '@mantine/core';
import { themeColors } from '../../../shared/theme/theme';
import { IFieldComponentProps } from '../types';

export const DropzoneField = ({ form, disabled }: IFieldComponentProps & { disabled?: boolean }) => (
  <Box>
    <Dropzone
      onDrop={(files) => form.setFieldValue('mainMediaFile', Array.isArray(files) ? files[0] : files)}
      disabled={disabled}
    />

    {form.errors.mainMediaFile && (
      <>
        <Space h={'xs'} />
        <Text c={themeColors.danger} mt={5}>
          {form.errors.mainMediaFile}
        </Text>
      </>
    )}

    {form.values.mainMediaFile && (
      <>
        <Space h={'xs'} />
        <Badge color={themeColors.primary} maw={'100%'}>
          <span style={{ color: themeColors.warning }}>Выбран:</span> {form.values.mainMediaFile?.name}
        </Badge>
      </>
    )}
  </Box>
);
