import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Layout from '../../components/Layout';
import {Button, Card, NumberInput, Space, Text, Textarea, Title} from '@mantine/core';
import {useForm} from '@mantine/form';
import {api} from '../../api';
import {urlJoiner} from '../../utils';

interface IValues {amount: number | null, comment?: string}

const DonatePage = observer<any>(() => {
  useEffect(() => {
    document.title = 'VideoCensor — Поддержать проект | Автоматически удалить/запикать мат в видео';
  }, []);

    const form = useForm<IValues>({
        initialValues: {
            amount: null,
        },
        validate: {
            amount: (value) => {
                if (!value) return 'Необходимо ввести сумму пожертвования';
            },
        },
    });

  const onSubmit = async ({amount, comment = 'Поддержка проекта'}: IValues) => {
      const { data } = await api.post(
          urlJoiner(process.env.REACT_APP_API, '/donate'),
          { amount, comment },
      );

      window.location.replace(data.confirmationUrl);
  };

  return <Layout title={'Поддержать проект'}>
      <Card shadow="lg" padding="xl" radius="md" withBorder style={{gap: '1rem'}}>
          <Title order={2}>Поддержать проект</Title>

          <Text>Мы благодарим Вас ❤️ за проявленный интерес к нашему проекту!</Text>

          <form name="uploadMediaForm" onSubmit={form.onSubmit(onSubmit)} id={'donateForm'}>
              <NumberInput
                  label="Введите сумму, которую хотите пожертвовать:"
                  withAsterisk
                  placeholder="Сумма в рублях"
                  suffix={' ₽'}
                  {...form.getInputProps('amount')}
                  min={0}
              />
              <Space h={'8px'}/>
              <Textarea
                  label="Может быть есть пожелания?"
                  {...form.getInputProps('comment')}
              />
              <Space h={'8px'}/>
              <Button type="submit">Поддержать проект</Button>
          </form>
      </Card>
  </Layout>
});

export default DonatePage;
