import React from 'react';
import {ActionIcon, Anchor, Flex, Group, rem, Text} from '@mantine/core';
import {themeColors} from '../../../shared/theme/theme';
import {IconBrandInstagram, IconBrandTelegram, IconMail} from '@tabler/icons-react';
import classes from './footer.module.scss';
import {Link} from 'react-router-dom';

export const Footer = () => (
    <div className={classes.footer}>
        <Group className={classes.socials} gap="xs" justify="flex-end" wrap="nowrap">
            <ActionIcon size="lg" variant="default" radius="xl" component="a" href="https://t.me/ganjavee" target="_blank" rel="noopener noreferrer">
                <IconBrandTelegram style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl" component="a" href="mailto:nizzamko@gmail.com" target="_blank" rel="noopener noreferrer">
                <IconMail style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl" component="a" href="https://instagram.com/iam.nizzami" target="_blank" rel="noopener noreferrer">
                <IconBrandInstagram style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
            </ActionIcon>
        </Group>

        <Flex direction='column' align={'center'}>
            <Anchor c={'white'} component={Link} to={'/about'}>
                О Нас
            </Anchor>
            <Text className={classes.creator}>© {new Date().getFullYear()} Created with {'❤️'} by Nizami Dzhumaev</Text>
        </Flex>

        <Flex direction={'column'} align={'center'}>
            <Anchor className={classes.privacy} c={themeColors.lightFontC} href="https://videocensor.ru/files/docs/privacy.pdf" rel="noreferrer"
                    target="_blank">Конфиденциальность</Anchor>
            <Anchor className={classes.privacy} c={themeColors.lightFontC} href="https://videocensor.ru/files/docs/user_agreement.pdf" rel="noreferrer"
                    target="_blank">Пользовательское соглашение</Anchor>
        </Flex>
    </div>
)