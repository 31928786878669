import { Box, Button, Progress } from '@mantine/core';
import classes from './uploadMediaForm.module.scss';
import { themeColors } from '../../shared/theme/theme';
import { IProgress } from './types';

export const SubmitButton = ({ isFetching, progress }: { isFetching: boolean; progress: IProgress }) => (
  <Button type="submit" styles={{ root: { border: 'none' } }}>
    {isFetching && (
      <Progress
        pos={'absolute'}
        h={'100%'}
        value={progress.value}
        radius="sm"
        classNames={{ root: classes.progress }}
        color={themeColors.secondary}
        bg={themeColors.primary}
        animated
      />
    )}

    <Box pos={'relative'} style={{ zIndex: 1, color: '#ffffff' }}>
      {(() => {
        if (isFetching && progress.status === 'uploading') {
          return 'Отправляем данные';
        }
        if (isFetching && progress.status === 'processing') {
          return 'Данные обрабатываются';
        }
        if (isFetching && progress.status === 'downloading') {
          return 'Получаем данные';
        }

        return 'Отправить';
      })()}
    </Box>
  </Button>
);
