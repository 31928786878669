import { Box, Button, Group, Overlay, rem, Space, Text } from '@mantine/core';
import { IconUpload, IconX } from '@tabler/icons-react';
import { Dropzone as DropzoneUI, DropzoneProps } from '@mantine/dropzone';
import React, { useRef } from 'react';
import userInfo from '../../store/UserInfo';
import {daysUntilSubscriptionEnds} from '../../pages/ProfilePage/ProfilePage';

const Dropzone = (
  props: Partial<DropzoneProps> & { onDrop: (file: File) => void; onReject?: (file: File) => void },
) => {
  const openRef = useRef<() => void>(null);

  return (
    <Box pos={'relative'}>
      <DropzoneUI {...props}>
        <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
          <DropzoneUI.Accept>
            <IconUpload
              style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
              stroke={1.5}
            />
          </DropzoneUI.Accept>
          <DropzoneUI.Reject>
            <IconX style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }} stroke={1.5} />
          </DropzoneUI.Reject>
          <DropzoneUI.Idle>
            <div style={{textAlign: 'center'}}>
              <Button onClick={() => openRef.current?.()} style={{ pointerEvents: 'all' }} size="xl">
                Выбрать файл
              </Button>
              <Space h={'xs'} />
              <Text size={'xs'} c="dimmed">
                Максимальный вес файла — {daysUntilSubscriptionEnds(userInfo.subscriptionExpiration) > 0 ? 300 : 10} МБ
              </Text>
            </div>
          </DropzoneUI.Idle>
        </Group>
      </DropzoneUI>
      {props.disabled && <Overlay opacity={0} />}
    </Box>
  );
};

export default Dropzone;
