import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useForm} from '@mantine/form';
import {Button, Drawer, Fieldset, Tooltip} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import result, {IResult} from 'src/store/Result';
import {api} from 'src/api';
import classes from './uploadMediaForm.module.scss';
import {IProgress, IValues} from './types';
import {WayOfBLockingWordsField} from './fields/WayOfBLockingWordsField';
import {DropzoneField} from './fields/DropzoneField';
import {CustomWordsField} from './fields/CustomWordsField';
import {CustomWhiteList} from './fields/CustomWhiteList';
import {AxiosResponse} from 'axios';
import {SubmitButton} from './SubmitButton';
import {WayOfUsingCustomWordsRadiosField} from './fields/WayOfUsingCustomWordsRadiosField';
import {urlJoiner} from 'src/utils';
import {errorHandler} from '../../utils/errorHandler';
import {daysUntilSubscriptionEnds} from '../../pages/ProfilePage/ProfilePage';
import userInfo from '../../store/UserInfo';
import {themeColors} from '../../shared/theme/theme';

const UploadMediaForm = ({id}: {id: string}) => {
  const navigate = useNavigate();
  const form = useForm<IValues>({
    initialValues: {
      mainMediaFile: null,
      replaceSound: null,
      wayOfBlockingWords: 'default',
      customWords: [],
      customWhitelist: [],
      wayOfUsingCustomWords: 'addToDefaultDict',
    },
    validate: {
      mainMediaFile: (value) => {
        if (!value) return 'Необходимо выбрать файл';
      },
    },
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [progress, setProgress] = useState<IProgress>({
    value: 0,
    status: 'uploading',
  });

  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = useCallback(async (values: IValues) => {
    setIsFetching(true);

    try {
      const token = window.localStorage.getItem('token');
      const headers = token ? {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`
      } : {};

      const formData = new FormData();
      const { mainMediaFile, replaceSound, wayOfBlockingWords, customWords, wayOfUsingCustomWords, customWhitelist } =
        values;

      formData.set('mainMediaFile', mainMediaFile as Blob, mainMediaFile?.name);
      if (replaceSound) formData.set('replaceSound', replaceSound as Blob, replaceSound?.name);
      formData.set('wayOfBlockingWords', wayOfBlockingWords);
      formData.set('customWords', JSON.stringify(customWords));
      formData.set('wayOfUsingCustomWords', wayOfUsingCustomWords);
      formData.set('customWhitelist', JSON.stringify(customWhitelist));

      const { data } = await api.post<any, AxiosResponse<IResult>>(
        urlJoiner(process.env.REACT_APP_API, '/cleaner'),
        formData,
        {
          headers,
          onUploadProgress: ({ progress = 0 }) => {
            const value = progress + Math.round(progress * 100);
            const status = value < 100 ? 'uploading' : 'processing';

            setProgress({ status, value });
          },
          onDownloadProgress: ({ progress = 0 }) => {
            setProgress({ status: 'downloading', value: progress + Math.round(progress * 100) });
          },
        },
      );

      result.setResult(data);
      navigate('/result');
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsFetching(false);
    }
  }, [navigate]);

  return (
    <form name="uploadMediaForm" onSubmit={form.onSubmit(onSubmit)} id={id}>
      <Fieldset className={classes.form} disabled={isFetching}>
        <DropzoneField form={form} disabled={isFetching} />

        <Tooltip color={themeColors.warning} disabled={daysUntilSubscriptionEnds(userInfo.subscriptionExpiration) > 0} label={'Дополнительные настройки доступны только с подпиской'}>
          <Button disabled={daysUntilSubscriptionEnds(userInfo.subscriptionExpiration) <= 0} onClick={open}>Дополнительные настройки</Button>
        </Tooltip>

        <Drawer
          offset={10}
          opened={opened}
          onClose={close}
          classNames={{ body: classes.additionalSettings }}
          position="right"
          size="80%"
          title={'Дополнительные настройки'}
        >
          <WayOfBLockingWordsField form={form} />
          <WayOfUsingCustomWordsRadiosField form={form} />
          <CustomWordsField form={form} />
          <CustomWhiteList form={form} />
        </Drawer>

        <SubmitButton isFetching={isFetching} progress={progress} />
      </Fieldset>
    </form>
  );
};

export default observer(UploadMediaForm);
