import axios from 'axios';
import {useCallback, useState} from 'react';

export const useDownload = () => {
  const [progress, setProgress] = useState<number | null>(null);

  const download = useCallback(async(downloadLink: string) => {
    const filename = downloadLink.split('/').pop();

    if (!filename) return;

    setProgress(0);

    const {data} = await axios(downloadLink, {responseType: 'blob', onDownloadProgress: ({progress}) => {
      if (progress === 1) {
        setProgress(null);
        return;
      }

      if (progress) {
          setProgress(Math.round(progress * 100));
        }
      }});

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return {download, progress}
};
