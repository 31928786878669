import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const Redirect = ({ link }: { link?: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(link || '/');
  }, [navigate, link]);

  return null;
};
