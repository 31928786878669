import {makeAutoObservable, runInAction} from 'mobx';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import {urlJoiner} from '../utils';

class UserInfo {
  name?: string;
  email: string = '';
  id: string = '';
  subscriptionExpiration?: string;
  isAuthed: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async update() {
    const token = window.localStorage.getItem('token');

    if (!token) return;

    const decodedToken = jwtDecode<{id: string}>(token);
    const {data} = await axios.post(
        urlJoiner(process.env.REACT_APP_API, '/refreshToken'),
        {id: decodedToken.id}
    )

    window.localStorage.setItem('token', data);

    const userInfo: {name?: string, email: string, id: string, subscriptionExpiration?:string} | null = token ? jwtDecode(token) : null;

    if (!userInfo) {
      return;
    }

    runInAction(() => {
      this.name = userInfo.name;
      this.id = userInfo.id;
      this.email = userInfo.email;
      this.subscriptionExpiration = userInfo.subscriptionExpiration;
      this.isAuthed = true;
    })
  }

  reset() {
    runInAction(() => {
      this.name = '';
      this.id = '';
      this.email = '';
      this.subscriptionExpiration = '';
      this.isAuthed = false;
      window.localStorage.removeItem('token');
    })
  }
}

const userInfo = new UserInfo();

export default userInfo;
