import { makeAutoObservable } from 'mobx';

export type IResult = {
  downloadLink?: string;
  count?: number;
  text: string
} | null;

class Result {
  result: IResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  setResult = (result: IResult) => {
    this.result = result;
  }

  reset = () => {
    this.result = null;
  }
}

const result = new Result();

export default result;
