import {Anchor, Button, Container, Modal, Paper, PasswordInput, Text, TextInput, Title} from '@mantine/core';
import {api} from '../../api';
import {AxiosResponse} from 'axios';
import {urlJoiner} from '../../utils';
import {useForm} from '@mantine/form';
import {errorHandler} from '../../utils/errorHandler';
import userInfo from '../../store/UserInfo';

type Fields = {
    email: string,
    password: string,
};

export const LoginModal = ({isOpen, onSuccess, onClose, onClickRegistrationButton}: {isOpen: boolean, onClose: () => void, onSuccess: (token: string) => void, onClickRegistrationButton: () => void}) => {
    const form = useForm<Fields>(
        {
            initialValues: {
                email: '',
                password: ''
            },
        validate: {
            email: (value) => {
                if (!value) return 'Необходимо ввести email';
            },
            password: (value) => {
                if (!value) return 'Необходимо ввести пароль';
            },
        },
    });

    const onSubmit = async (values: Fields) => {
        try {
            const formData = new FormData();
            const {email, password} = values;

            formData.set('email', email);
            formData.set('password', password);


            const {data} = await api.post<any, AxiosResponse>(
                urlJoiner(process.env.REACT_APP_API, '/login'),
                formData,
            );

            window.localStorage.setItem('token', data);
            userInfo.update();
            onSuccess(data);
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <Modal opened={isOpen} onClose={onClose}>
            <Container size={420} my={40}>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Title ta="center">
                        Авторизация
                    </Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Ещё нет аккаунта?{' '}
                        <Anchor onClick={onClickRegistrationButton} size="sm">
                            Регистрация
                        </Anchor>
                    </Text>

                    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                        <TextInput label="Email" placeholder="Введите email" required {...form.getInputProps('email')}/>
                        <PasswordInput label="Password" placeholder="Введите пароль" required mt="md" {...form.getInputProps('password')}/>
                        <Button type={'submit'} fullWidth mt="xl">
                            Войти
                        </Button>
                    </Paper>
                </form>
            </Container>
        </Modal>
    )
}