import { Container, createTheme, CSSVariablesResolver } from '@mantine/core';

import colors from './vars.module.scss';
import classes from './index.module.scss';

const { dark, primary, secondary, success, warning, danger, lightFontC, darkFontC, accent1 } = colors;

export const themeColors = {
  dark,
  primary,
  secondary,
  accent1,
  success,
  warning,
  danger,
  lightFontC,
  darkFontC,
};

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-primary-color-filled': themeColors.primary,
    '--mantine-primary-color-filled-hover': themeColors.secondary + 'bb',
  },
  light: {},
  dark: {},
});

export const theme = createTheme({
  components: {
    Radio: {
      defaultProps: { color: themeColors.primary },
    },
    Container: Container.extend({
      classNames: (_, { size }) => ({
        root: size === 'responsive' ? classes.responsiveContainer : '',
      }),
    }),
  },
});
