import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Layout from '../../components/Layout';
import {Anchor, Card, List, ListItem, Text, Title} from '@mantine/core';
import {Link} from 'react-router-dom';
import {routes} from '../../routes';

const AboutPage = observer<any>(() => {
  useEffect(() => {
    document.title = 'VideoCensor — О Нас | Автоматически удалить/запикать мат в видео';
  }, []);

  return <Layout title={'О Нас'}>
    <Card shadow="lg" padding="xl" radius="md" withBorder style={{gap: '1rem'}}>
        <Title order={2}>О проекте videocensor.ru</Title>

        <Text>Добро пожаловать на videocensor.ru – инновационный сервис, который поможет Вам удалить/запикать нежелательный контент, включая матерные выражения, из ваших видео и аудиозаписей. Наш сервис предоставляет простое и эффективное решение для создания качественного контента без нежелательных элементов.</Text>

        <Title order={3}>Что мы предлагаем:</Title>

        <List>
          <ListItem>
            <Text component={'span'} fw={700}>Удаление матерных выражений:</Text> videocensor.ru позволяет легко избавиться от матерных слов и выражений
            в ваших видео и аудиозаписях.
          </ListItem>
          <ListItem>
            <Text component={'span'} fw={700}>Новые возможности:</Text> Мы постоянно развиваем наш сервис и планируем добавить функционал для удаления/запикивания
            матерных выражений из песен(что является нетривиальной задачей).
          </ListItem>
          <ListItem>
            <Text component={'span'} fw={700}>Пользовательские звуки и настройки:</Text> Помимо удаления матерных слов, Вы можете добавлять собственные звуки или
            просто тишину вместо матерных выражений. Также есть возможность добавлять свои слова для запикания или
            исключать определенные слова из обработки.
          </ListItem>
        </List>

        <Title order={3}>Бесплатно и просто:</Title>

        <List>
          <ListItem>
            <Text component={'span'} fw={700}>Абсолютно бесплатно:</Text> Наш сервис доступен абсолютно бесплатно для всех пользователей.
          </ListItem>
          <ListItem>
            <Text component={'span'} fw={700}>Простота использования:</Text> Удалить или запикать мат из видео/аудио можно в два клика. Никаких сложных настроек или
            дополнительных платежей.
          </ListItem>
        </List>

        <Text>
          Не дайте нежелательному контенту испортить ваш контент! Присоединяйтесь к нашему сообществу и создавайте
          качественные видео и аудиозаписи без лишнего матерного содержания.
        </Text>

        <Text>
          Если у Вас возникли вопросы или предложения, не стесняйтесь связаться с нами по адресу <Anchor href={'mailto:nizzamko@gmail.com'} target={'_blank'}>nizzamko@gmail.com</Anchor> или в ТГ <Anchor href={'https://t.me/ganjavee'} target={'_blank'}>@ganjavee</Anchor>.
        </Text>
    </Card>
  </Layout>
});

export default AboutPage;
