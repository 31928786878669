import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Paper, Stepper, Text, Title} from '@mantine/core';
import Layout from 'src/components/Layout';
import UploadMediaForm from 'src/components/UploadMediaForm';
import classes from './main.module.scss';

const MainPage = observer<any>(() => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeStep === 3) {
                setActiveStep(0);
            } else {
                setActiveStep(aaa => aaa + 1);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [activeStep]);

  useEffect(() => {
    document.title = 'VideoCensor | Удалить мат из видео';
  }, []);

  return (
    <Layout title={'Главная'}>
      <Paper className={classes.paper} shadow="lg" p="lg">
          <Title className={classes.title} order={2}>Удаление мата из видео онлайн</Title>

          <Box className={classes.textWrapper}>
              <Text className={classes.text}>
                  Сервис автоматически удаляет("запикивает") из видео матерные слова/нецезурную лексику.
              </Text>

              <Stepper className={classes.stepper} active={activeStep} onClick={() => {
                  window.location.hash = '';
                  window.location.hash = 'form';
              }}>
                  <Stepper.Step className={classes.step} label='Нажмите "Выбрать файл"'>
                      <Text className={classes.stepText} ta='center'>
                          Нажмите кнопку "Выбрать файл", чтобы открыть окно выбора
                      </Text>
                  </Stepper.Step>
                  <Stepper.Step className={classes.step} label="Выберите файл">
                      <Text className={classes.stepText} ta='center'>
                          Выберите видео, содержащее нецезурную выражения
                      </Text>
                  </Stepper.Step>
                  <Stepper.Step className={classes.step} label='Нажмите "Отправить"'>
                      <Text className={classes.stepText} ta='center'>
                          Нажмите кнопку "Отправить", чтобы удалить нежелательные слова
                      </Text>
                  </Stepper.Step>
                  <Stepper.Completed>
                      <Text className={classes.stepText} ta='center'>
                          Дождитесь, пока сервис очистит Ваше видео! 🎉
                      </Text>
                  </Stepper.Completed>
              </Stepper>
          </Box>
      </Paper>
      <UploadMediaForm id="form" />
    </Layout>
  );
});

export default MainPage;
