import { notifications } from '@mantine/notifications';

export const errorHandler = (error: unknown) => {
  // @ts-ignore
  let title = error?.response?.data?.message || 'Что-то пошло не так';
  // @ts-ignore
  let message = error?.response?.data?.description || 'Попробуйте ещё раз';

  notifications.show({
    title,
    message,
    autoClose: 10000,
    color: 'red',
    withBorder: true,
  });
};
