import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.scss';
import result from './store/Result';
import ResultPage from './pages/ResultPage';
import MainPage from './pages/MainPage';
import {MantineProvider} from '@mantine/core';
import {Notifications} from '@mantine/notifications';
import {cssVariablesResolver, theme} from './shared/theme/theme';
import {Redirect} from './pages/Redirect';
import AboutPage from './pages/AboutPage';
import DonatePage from './pages/DonatePage';
import ProfilePage from './pages/ProfilePage';

import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/core/styles.css';
import userInfo from './store/UserInfo';

function App() {

    useEffect(() => {
        userInfo.update();
    }, []);
  return (
    <MantineProvider theme={theme} cssVariablesResolver={cssVariablesResolver}>
      <Notifications position="top-center" autoClose={5000} />
      <Router>
        <Routes>
          <Route path="/donate" element={<DonatePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/result" element={<ResultPage result={result} />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
