import React, {useState} from 'react';
import classes from './Header.module.scss';
import {Button, Container, Flex, Title} from '@mantine/core';
import logo from '../../../assets/logo/svg/logo-no-background.svg';
import {observer} from 'mobx-react-lite';
import {useMediaQuery} from '@mantine/hooks';
import {Link, useNavigate} from 'react-router-dom';
import {LoginModal} from '../../LoginModal';
import {RegistrationModal} from '../../RegistrationModal';
import {routes} from '../../../routes';
import userInfo from '../../../store/UserInfo';

export const useIsMobile = () => useMediaQuery('(max-width: 430px)');

export const Header = ({ title, className }: { title: string, className: string }) => {
  const isMobile = useIsMobile();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenRegistrationModal, setIsOpenRegistrationModal] = useState(false);
  const [token, setToken] = useState(window.localStorage.getItem('token'));
  const navigate = useNavigate();

  return (
    <header className={[classes.header, className].join(' ')}>
      <Container className={classes.container} size={'sm'} h={'100%'} w={'100%'}>
        <Flex justify={isMobile ? 'center' : 'space-between'} align={'center'} h={'100%'} w={'100%'} gap={'1rem'}>
          <Link style={
              {    height: '100%',
            width: 'auto',
              maxWidth: '50%'}
          }
                to="/"
          >
              <img className={classes.image} src={logo} alt="Логотип"/>
          </Link>
          <Title className={classes.title} visibleFrom="md" size={'1.8rem'}>
            {title}
          </Title>
            <div>
                {
                    userInfo.isAuthed ? (
                    <Button variant='white' size='md' onClick={() => navigate(routes.profile.link)}>Профиль</Button>
                ) : <>
                      <Button variant='white' size='md' onClick={() => setIsOpenLoginModal(true)}>
                        Войти
                      </Button>

                        <LoginModal
                            isOpen={isOpenLoginModal}
                            onClose={() => setIsOpenLoginModal(false)}
                            onSuccess={(newToken) => {
                                setIsOpenLoginModal(false);
                                setToken(newToken)
                        }}
                        onClickRegistrationButton={() => {
                            setIsOpenLoginModal(false)
                            setIsOpenRegistrationModal(true);
                        }}/>

                        <RegistrationModal
                            isOpen={isOpenRegistrationModal}
                            onClose={() => setIsOpenRegistrationModal(false)}
                            onSuccess={(newToken) => {
                            setIsOpenRegistrationModal(false);
                            setToken(newToken)
                        }}
                        onClickLoginButton={() => {
                            setIsOpenRegistrationModal(false);
                            setIsOpenLoginModal(true)
                        }}/>
                    </>
                }
            </div>
        </Flex>
      </Container>
    </header>
  );
};

export default observer(Header);
